import api from "@/services/api/collaborateurs.js";
import { getField, updateField } from "vuex-map-fields";

// initial state
const state = {
  collaborateursAll: [],
  collaborateursTeam: [],
  collaborateursPcr: [],
  collaborateurs: [],
  collaborateur: {},
  data: {},
  contrats: [],
  contratParams: [],
  vms: [],
  formations: [],
  mooc: [],
  habilitations: [],
  competences: [],
  acces: [],
  materiels: [],
  entretiens: [],
  expositions: [],
  users: [],
  doses: [],
  collaborateurRoles: [],
  accueils: []
};

// getters
const getters = {
  getField,

  data: function(state) {
    return state.data;
  },

  all: function(state) {
    return state.collaborateursAll;
  },

  accueils: function(state) {
    return state.accueils;
  }
};

// actions
const actions = {
  getCollaborateursAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then(response => {
          commit("setCollaborateursAll", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCollaborateursTeamLight({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getTeamLight()
        .then(response => {
          commit("setCollaborateursTeamLight", response.data); // set in STATE : collaborateursAll
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCollaborateursTeamChiffrage({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getTeamChiffrage()
        .then(response => {
          commit("setCollaborateursTeamChiffrage", response.data); // set in STATE : collaborateursAll
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCollaborateursPcr({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getPcr()
        .then(response => {
          commit("setCollaborateursPcr", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCollaborateurs({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getRecents()
        .then(response => {
          commit("setCollaborateurs", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCollaborateur({ commit }, collab_id) {
    return new Promise((resolve, reject) => {
      api
        .getCollaborateur(collab_id)
        .then(response => {
          commit("setCollaborateur", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then(response => {
          commit("setData", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCollaborateur({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createCollaborateur(data)
        .then(response => {
          commit("createCollaborateur", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCollaborateur({ state, commit }) {
    return new Promise((resolve, reject) => {
      api
        .updateCollaborateur(state.collaborateur)
        .then(() => {
          commit("updateCollaborateur", state.collaborateur);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCollaborateur({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteCollaborateur(data.id)
        .then(() => {
          commit("deleteCollaborateur", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createContrat({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createContrat(data)
        .then(response => {
          commit("createContrat", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateContrat({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateContrat(data)
        .then(response => {
          commit("updateContrat", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteContrat({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteContrat(data.id)
        .then(() => {
          commit("deleteContrat", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getContratParams({ commit }, versionId) {
    return new Promise((resolve, reject) => {
      api
        .getContratParams(versionId)
        .then(response => {
          commit("setContratParams", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createContratParams({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createContratParams(data)
        .then(response => {
          commit("createContratParams", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateContratParams({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateContratParams(data)
        .then(response => {
          commit("updateContratParams", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteContratParams({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteContratParams(data.id)
        .then(() => {
          commit("deleteContratParams", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createVm({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createVm(data)
        .then(response => {
          commit("createVm", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateVm({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateVm(data)
        .then(response => {
          commit("updateVm", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteVm({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteVm(data.id)
        .then(() => {
          commit("deleteVm", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createFormation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createFormation(data)
        .then(response => {
          commit("createFormation", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateFormation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateFormation(data)
        .then(response => {
          commit("updateFormation", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteFormation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteFormation(data.id)
        .then(() => {
          commit("deleteFormation", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createHabilitation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createHabilitation(data)
        .then(response => {
          commit("createHabilitation", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateHabilitation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateHabilitation(data)
        .then(response => {
          commit("updateHabilitation", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteHabilitation({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteHabilitation(data.id)
        .then(() => {
          commit("deleteHabilitation", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createCompetence({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createCompetence(data)
        .then(response => {
          commit("createCompetence", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCompetence({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateCompetence(data)
        .then(() => {
          commit("updateCompetence");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateTutorat({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateTutorat(data)
        .then(() => {
          commit("updateTutorat");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteCompetence({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteCompetence(data.id)
        .then(() => {
          commit("deleteCompetence", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createAcces({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createAcces(data)
        .then(response => {
          commit("createAcces", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateAcces({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateAcces(data)
        .then(response => {
          commit("updateAcces", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteAcces({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteAcces(data.id)
        .then(() => {
          commit("deleteAcces", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createMateriel(data)
        .then(response => {
          commit("createMateriel", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateMateriel(data)
        .then(() => {
          commit("updateMateriel");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteMateriel(data.id)
        .then(() => {
          commit("deleteMateriel", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createEntretien({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createEntretien(data)
        .then(response => {
          commit("createEntretien", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateEntretien({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateEntretien(data)
        .then(() => {
          commit("updateEntretien");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteEntretien({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteEntretien(data.id)
        .then(() => {
          commit("deleteEntretien", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createExposition({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createExposition(data)
        .then(response => {
          commit("createExposition", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateExposition({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateExposition(data)
        .then(response => {
          commit("updateExposition", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteExposition({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteExposition(data.id)
        .then(() => {
          commit("deleteExposition", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  autorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .autorizeUser(data)
        .then(() => {
          commit("autorizeUser", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  unautorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .unautorizeUser(data)
        .then(() => {
          commit("unautorizeUser", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  assignRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .assignRole(data)
        .then(response => {
          commit("assignRole", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  revokeRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .revokeRole(data)
        .then(() => {
          commit("revokeRole", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  assignMooc({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .assignMooc(data)
        .then(response => {
          commit("assignMooc", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  revokeMooc({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .revokeMooc(data)
        .then(() => {
          commit("revokeMooc", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  assignAccueil({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .assignAccueil(data)
        .then(response => {
          commit("assignAccueil", response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  revokeAccueil({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .revokeAccueil(data)
        .then(() => {
          commit("revokeAccueil", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  updateField,

  setCollaborateurs(state, data) {
    state.collaborateurs = data;
  },

  setCollaborateursAll() {
    //
  },

  setCollaborateursTeamLight(state, data) {
    state.collaborateursAll = data;
  },

  setCollaborateursTeamChiffrage(state, data) {
    state.collaborateursAll = data;
  },

  setCollaborateursPcr(state, data) {
    state.collaborateursPcr = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setFormationLibelles(state, data) {
    state.formationLibelles = data;
  },

  setCollaborateur(state, data) {
    state.collaborateur = data;
    state.contrats = data.collaborateur_contrats;
    state.vms = data.collaborateur_medical;
    state.formations = data.collaborateur_formation;
    state.mooc = data.collaborateur_mooc;
    state.habilitations = data.collaborateur_habilitation;
    state.competences = data.collaborateur_competence;
    state.acces = data.collaborateur_acces;
    state.materiels = data.collaborateur_materiel;
    state.entretiens = data.collaborateur_entretien;
    state.expositions = data.collaborateur_exposition;
    state.users = data.collaborateur_users;
    state.collaborateurRoles = data.collaborateur_roles;
    state.accueils = data.collaborateur_accueil;
  },

  setContratParams(state, data) {
    state.contratParams = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createCollaborateur(state, data) {
    state.collaborateurs.push({
      id: data.id,
      collaborateur_matricule: null,
      collaborateur_nom: data.collaborateur_nom,
      collaborateur_prenom: data.collaborateur_prenom,
      collaborateur_rinpp: null,
      collaborateur_sexe_id: null,
      collaborateur_nationalite_id: null,
      collaborateur_date_naissance: null,
      collaborateur_adresse_naissance: null,
      collaborateur_situation_famille_id: null,
      collaborateur_enfants: null,
      collaborateur_niveau_formation_id: null,
      collaborateur_type_doc: null,
      collaborateur_doc_validite: null,
      collaborateur_num_ordre: null,
      collaborateur_email_pro: null,
      collaborateur_email_perso: null,
      collaborateur_tel_1: null,
      collaborateur_tel_2: null,
      collaborateur_tel_3: null,
      collaborateur_tel_poste: null,
      collaborateur_adresse: null,
      collaborateur_carnet_acces: null,
      collaborateur_carte_orange: null,
      collaborateur_badge_edf: null,
      collaborateur_badge_cea: null,
      collaborateur_badge_autre: null,
      collaborateur_categorie_id: 3,
      collaborateur_etranger_num_ordre: null,
      collaborateur_etranger_type_doc: null,
      collaborateur_etranger_validite: null,
      collaborateur_rqth_validite: null,
      collaborateur_handicape: false,
      collaborateur_contrats: [],
      collaborateur_roles: [],
      collaborateur_new: true,
      user_active: true,
      qwark_manager: false,
      anciennete: null,
      task_commentaire: null,
      objectif_mensuel_animations: null,
      collaborateur_hierarchique_id: null,
      contact_nom: null,
      contact_prenom: null,
      contact_tel: null,
      contact_email: null
    });
  },

  updateCollaborateur() {
    //
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteCollaborateur(state, data) {
    const index = state.collaborateurs.findIndex(x => x.id === data.id);
    state.collaborateurs.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createContrat(state, data) {
    state.contrats.push(data);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateContrat() {
    //
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteContrat(state, data) {
    const index = state.contrats.findIndex(x => x.id === data.id);
    state.contrats.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createContratParams(state, data) {
    state.contratParams.push(data);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateContratParams() {
    //
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteContratParams(state, data) {
    const index = state.contratParams.findIndex(x => x.id === data.id);
    state.contratParams.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createVm(state, data) {
    state.vms.push({
      id: data.id,
      vm_type_id: null,
      vm_etat_id: null,
      vm_organisme_id: null,
      vm_medecin_id: null,
      vm_date: null,
      vm_date_validite: null,
      vm_aptitude_spe: null,
      vm_commentaire: null
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateVm(state, data) {
    const index = state.vms.findIndex(x => x.id === data.id);
    state.vms[index].vm_active = data.vm_active;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteVm(state, data) {
    const index = state.vms.findIndex(x => x.id === data.id);
    state.vms.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createFormation(state, data) {
    state.formations.push({
      id: data.id,
      formation_collaborateur_id: null,
      formation_etat_id: null,
      formation_organisme_id: null,
      formation_type_id: null,
      formation_libelle_id: null,
      formation_attestation: null,
      formation_date_debut: null,
      formation_date_fin: null,
      formation_date_validite: null,
      formation_facture_ref: null,
      formation_montant_arkadia: null,
      formation_opco_id: null,
      formation_montant_opca: null,
      formation_commentaire: null,
      formation_active: null,
      alert_delais: null,
      alert_mail: null,
      alert_niveau: null,
      formation_avec_arkadia: true,
      formation_montant_total_ark: null,
      formation_obligatoire: null,
      formation_societe_id: null,
      formation_nbre_heures: 0
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateFormation(state, data) {
    const index = state.formations.findIndex(x => x.id === data.id);
    state.formations[index].formation_active = data.formation_active;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteFormation(state, data) {
    const index = state.formations.findIndex(x => x.id === data.id);
    state.formations.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createHabilitation(state, data) {
    state.habilitations.push({
      id: data.id,
      habilitation_active: false,
      habilitation_collaborateur_id: data.habilitation_collaborateur_id,
      habilitation_libelle_id: null,
      habilitation_domaine_id: null,
      habilitation_date: null,
      habilitation_date_validite: null,
      habilitation_commentaire: null
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateHabilitation(state, data) {
    const index = state.habilitations.findIndex(x => x.id === data.id);
    state.habilitations[index].habilitation_active = data.habilitation_active;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteHabilitation(state, data) {
    const index = state.habilitations.findIndex(x => x.id === data.id);
    state.habilitations.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createCompetence(state, data) {
    state.competences.push({
      id: data.id,
      competence_collaborateur_id: null,
      competence_libelle_id: null,
      competence_secteur_id: null,
      competence_niveau_id: null,
      competence_type_id: null,
      competence_critique: null,
      libelle_precision: null,
      date_identification: null,
      competence_commentaire: null
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteCompetence(state, data) {
    const index = state.competences.findIndex(x => x.id === data.id);
    state.competences.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createAcces(state, data) {
    state.acces.push({
      id: data.id,
      acces_active: false,
      acces_collaborateur_id: data.acces_collaborateur_id,
      acces_type_id: null,
      acces_site_client_id: null,
      acces_date_demande: null,
      acces_date_validite: null,
      acces_commentaire: null
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateAcces(state, data) {
    const index = state.acces.findIndex(x => x.id === data.id);
    state.acces[index].acces_active = data.acces_active;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteAcces(state, data) {
    const index = state.acces.findIndex(x => x.id === data.id);
    state.acces.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createMateriel(state, data) {
    state.materiels.push({
      id: data.id,
      identifiant: null,
      description: null,
      materiel_type_id: null,
      materiel_collab_id: null,
      materiel_societe_id: null,
      materiel_fournisseur_id: null,
      date_commande: null,
      date_livraison: null,
      date_validite: null,
      commentaire: null
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteMateriel(state, data) {
    const index = state.materiels.findIndex(x => x.id === data.id);
    state.materiels.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createEntretien(state, data) {
    state.entretiens.push({
      id: data.id,
      date_realisation: data.date_realisation,
      date_renouvellement: data.date_renouvellement,
      bilan: data.bilan
      //faits_marquants: data.faits_marquants,
      //synthese_annee_passee: data.synthese_annee_passee,
      //synthese_qsser_annee_passee: data.synthese_qsser_annee_passee,
      //objectif_annee_suivante: data.objectif_annee_suivante,
      //formation_realisee: data.formation_realisee,
      //formation_souhaitee: data.formation_souhaitee,
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteEntretien(state, data) {
    const index = state.entretiens.findIndex(x => x.id === data.id);
    state.entretiens.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createExposition(state, data) {
    state.expositions.push({
      id: data.id,
      exposition_collaborateur_id: null,
      exposition_type_id: null,
      exposition_categorie_id: null,
      exposition_date: null,
      exposition_date_validite: null,
      exposition_dose_prevue: null,
      exposition_commentaire: null,
      type: {},
      categorie: {}
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateExposition(state, data) {
    const index = state.expositions.findIndex(x => x.id === data.id);
    state.expositions[index].exposition_active = data.exposition_active;
    state.expositions[index].type = data.type;
    state.expositions[index].categorie = data.categorie;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteExposition(state, data) {
    const index = state.expositions.findIndex(x => x.id === data.id);
    state.expositions.splice(index, 1);
  },

  autorizeUser(state, data) {
    state.users.push({
      user_id: data.user_id
    });
  },

  unautorizeUser(state, data) {
    const index = state.users.findIndex(x => x.user_id === data.user_id);
    state.users.splice(index, 1);
  },

  updateEntretien(state) {
    return state;
  },

  updateMateriel(state) {
    return state;
  },

  updateCompetence(state) {
    return state;
  },

  updateTutorat(state) {
    return state;
  },

  assignRole(state, data) {
    state.collaborateurRoles.push(data);
  },

  revokeRole(state, data) {
    const index = state.collaborateurRoles.findIndex(
      x => x.id === data.role.id
    );
    state.collaborateurRoles.splice(index, 1);
  },

  assignMooc(state, data) {
    state.mooc.push(data);
  },

  revokeMooc(state, data) {
    const index = state.mooc.findIndex(
      x => x.formation_id === data.formation_id
    );
    state.mooc.splice(index, 1);
  },

  assignAccueil(state, data) {
    state.accueils.push(data);
  },

  revokeAccueil(state, data) {
    const index = state.accueils.findIndex(
      x => x.accueil_id === data.accueil_id
    );
    state.accueils.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
