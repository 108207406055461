<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="w-100 d-flex justify-content-between align-items-center p-2">
        <div class="d-flex align-items-center">
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-success mr-2"
            fill="#28a745"
            v-if="action.action_etat_id == 3"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-primary mr-2"
            fill="#007bff"
            v-if="action.action_etat_id == 2"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-danger mr-2"
            fill="#dc3545"
            v-if="action.action_etat_id == 1"
          ></BaseIcon>
          <div>
            <span class="text-primary" v-if="action.action_new"
              >Nouvelle action à saisir</span
            >
            <span class="une-lignes" v-if="!action.action_new">
              <span class="mr-2">Action n°{{ action.id }}</span>
              <span class="text-muted">{{ action.description }}</span>
            </span>
          </div>
        </div>
        <div>
          <small class="text-muted">
            <div
              class="text-right"
              style="min-width: 9rem"
              v-if="action.action_debut"
            >
              <span class="mr-1">
                {{ $moment(action.action_debut).format("DD/MM/YYYY") }}
              </span>
              <span
                class="text-danger"
                v-if="
                  action.action_delais && !delaisDepasse && !action.action_fin
                "
              >
                {{ $moment(action.action_delais).format("DD/MM/YYYY") }}
              </span>
              <span
                class="text-primary"
                v-if="
                  (action.action_delais && delaisDepasse) || action.action_fin
                "
              >
                {{ $moment(action.action_delais).format("DD/MM/YYYY") }}
              </span>
            </div>
          </small>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        deleteBtn
        @click="updateActions(action)"
        @delete="deleteActions(action)"
        :loading="loading"
      >
        <base-form-row row>
          <select-collaborateur
            class="mb-3"
            v-model.number="action.action_pilote_id"
            :text="'Pilote'"
          ></select-collaborateur>
        </base-form-row>

        <base-form-row row>
          <base-select
            inputText="Origine"
            v-model.number="action.action_origineact_id"
            :options="data.actionOrigineacts"
            :errors="feedback.action_origineact_id"
          ></base-select>
        </base-form-row>

        <base-form-row row>
          <base-input
            v-model="action.description"
            inputText="Libellé"
            :errors="feedback.description"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="action.action_details"
            text="Détails de l'action"
            :errors="feedback.action_details"
          ></base-input-area>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="action.action_debut"
            inputText="Début"
            :errors="feedback.action_debut"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="action.action_delais"
            inputText="Fin prévue"
            :errors="feedback.action_delais"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="action.action_fin"
            inputText="Date de fin"
            :errors="feedback.action_fin"
          ></base-inputDatePicker>
          <base-select
            inputText="Etat"
            v-model.number="action.action_etat_id"
            :options="data.actionEtats"
            :errors="feedback.action_etat_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="action.action_type_id"
            :options="data.actionTypes"
            :errors="feedback.action_type_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="action.action_verif_critere"
            text="Critères de vérification d'efficacité"
            :errors="feedback.action_verif_critere"
          ></base-input-area>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="action.action_verif_commentaire"
            text="Résultat de la vérification d'efficacité"
            :errors="feedback.action_verif_commentaire"
          ></base-input-area>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="action.action_verif_nonconformites"
            text="Description des non confirmités"
            :errors="feedback.action_verif_nonconformites"
          ></base-input-area>
        </base-form-row>
        <uploader
          v-if="action.id"
          :id="'document_qsser_actions' + action.id"
          url="/upload/document"
          title="Déposer vos fichiers"
          :file_system="'QSSER/actions'"
          :file_field="'action'"
          :file_key="action.id"
        ></uploader>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput,
    BaseSelect,
    Uploader,
    BaseIcon,
    SelectCollaborateur
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {}
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _updateActions: "qsser/updateActions",
      _deleteActions: "qsser/deleteActions"
    }),
    updateActions: function(action) {
      this.feedback = {};
      this.loading = true;
      this._updateActions(action)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteActions: function(action) {
      if (!window.confirm("Confirmer la suppression de l'élément ?"))
        return null;
      this.feedback = {};
      this.loading = true;
      this._deleteActions(action)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  },
  props: ["action"],
  computed: {
    ...mapGetters({
      data: "qsser/data"
    }),
    delaisDepasse() {
      return this.$moment(this.action.action_delais).isSameOrAfter(
        this.$moment()
      );
    }
  }
};
</script>
<style>
.une-lignes {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
