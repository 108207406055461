import request from "@/request.js";

const BASE = "/collaborateurs";

export default {
  getAll() {
    return request.get(BASE + "/all");
  },
  getTeamLight() {
    return request.get(BASE + "/teamligth");
  },
  getTeamChiffrage() {
    return request.get(BASE + "/teamchiffrage");
  },
  getPcr() {
    return request.get(BASE + "/pcr");
  },
  getRecents() {
    return request.get(BASE + "/recents");
  },
  getCollaborateur(collab_id) {
    return request.get(BASE + "/" + collab_id);
  },
  getData() {
    return request.get(BASE + "/data");
  },

  // CRUD COLLABORATEUR
  createCollaborateur(payload) {
    return request.put(BASE, payload);
  },
  updateCollaborateur(payload) {
    return request.post(BASE, {
      id: payload.id,
      collaborateur_matricule: payload.collaborateur_matricule,
      collaborateur_nom: payload.collaborateur_nom,
      collaborateur_prenom: payload.collaborateur_prenom,
      collaborateur_rinpp: payload.collaborateur_rinpp,
      collaborateur_sexe_id: payload.collaborateur_sexe_id,
      collaborateur_nationalite_id: payload.collaborateur_nationalite_id,
      collaborateur_date_naissance: payload.collaborateur_date_naissance,
      collaborateur_adresse_naissance: payload.collaborateur_adresse_naissance,
      collaborateur_situation_famille_id:
        payload.collaborateur_situation_famille_id,
      collaborateur_enfants: payload.collaborateur_enfants,
      collaborateur_niveau_formation_id:
        payload.collaborateur_niveau_formation_id,
      collaborateur_type_doc: payload.collaborateur_type_doc,
      collaborateur_doc_validite: payload.collaborateur_doc_validite,
      collaborateur_email_pro: payload.collaborateur_email_pro,
      collaborateur_email_perso: payload.collaborateur_email_perso,
      collaborateur_tel_1: payload.collaborateur_tel_1,
      collaborateur_tel_2: payload.collaborateur_tel_2,
      collaborateur_tel_3: payload.collaborateur_tel_3,
      collaborateur_tel_poste: payload.collaborateur_tel_poste,
      collaborateur_adresse: payload.collaborateur_adresse,
      collaborateur_carnet_acces: payload.collaborateur_carnet_acces,
      collaborateur_carte_orange: payload.collaborateur_carte_orange,
      collaborateur_badge_edf: payload.collaborateur_badge_edf,
      collaborateur_badge_cea: payload.collaborateur_badge_cea,
      collaborateur_badge_autre: payload.collaborateur_badge_autre,
      collaborateur_categorie_id: payload.collaborateur_categorie_id,
      collaborateur_etranger_num_ordre:
        payload.collaborateur_etranger_num_ordre,
      collaborateur_etranger_type_doc: payload.collaborateur_etranger_type_doc,
      collaborateur_etranger_validite: payload.collaborateur_etranger_validite,
      collaborateur_handicape: payload.collaborateur_handicape,
      collaborateur_rqth_validite: payload.collaborateur_rqth_validite,
      user_active: payload.user_active,
      qwark_manager: payload.qwark_manager,
      anciennete: payload.anciennete,
      task_commentaire: payload.task_commentaire,
      objectif_mensuel_animations: payload.objectif_mensuel_animations,
      collaborateur_hierarchique_id: payload.collaborateur_hierarchique_id,
      contact_nom: payload.contact_nom,
      contact_prenom: payload.contact_prenom,
      contact_tel: payload.contact_tel,
      contact_email: payload.contact_email
    });
  },
  deleteCollaborateur(id) {
    return request.delete(BASE + "/" + id);
  },

  // CRUD CONTRAT
  createContrat(payload) {
    return request.put(BASE + "/contrats", {
      contrat_collaborateur_id: payload.contrat_collaborateur_id,
      contrat_libelle: payload.contrat_libelle,
      contrat_type_id: payload.contrat_type_id,
      contrat_siret_id: payload.contrat_siret_id,
      contrat_date_debut: payload.contrat_date_debut,
      contrat_date_fin: payload.contrat_date_fin
    });
  },
  updateContrat(payload) {
    return request.post(BASE + "/contrats", {
      id: payload.id,
      contrat_type_id: payload.contrat_type_id,
      contrat_statut_id: payload.contrat_statut_id,
      contrat_siret_id: payload.contrat_siret_id,
      contrat_rupture_id: payload.contrat_rupture_id,
      contrat_prop_siret_id: payload.contrat_prop_siret_id,
      contrat_pe_rupture_id: payload.contrat_pe_rupture_id,
      contrat_date_debut: payload.contrat_date_debut,
      contrat_date_fin: payload.contrat_date_fin,
      contrat_prop_date_envois: payload.contrat_prop_date_envois,
      contrat_prop_date_retour: payload.contrat_prop_date_retour,
      contrat_pe_date_fin: payload.contrat_pe_date_fin,
      contrat_pe_renouvellement: payload.contrat_pe_renouvellement,
      contrat_pe_date_courrier: payload.contrat_pe_date_courrier,
      contrat_libelle: payload.contrat_libelle,
      contrat_rupture_commentaire: payload.contrat_rupture_commentaire,
      contrat_mention_spe: payload.contrat_mention_spe,
      contrat_temps_travail: payload.contrat_temps_travail,
      contrat_position: payload.contrat_position,
      contrat_coef: payload.contrat_coef,
      contrat_brut_annuel: payload.contrat_brut_annuel,
      contrat_base_mensuelle: payload.contrat_base_mensuelle,
      contrat_taux_horaire: payload.contrat_taux_horaire,
      contrat_code_comptable: payload.contrat_code_comptable,
      contrat_rem_variable: payload.contrat_rem_variable,
      contrat_rtt: payload.contrat_rtt,
      contrat_temps_partiel: payload.contrat_temps_partiel,
      contrat_virement: payload.contrat_virement,
      contrat_bulletin_mail: payload.contrat_bulletin_mail,
      contrat_prop_reponse: payload.contrat_prop_reponse,
      contrat_pe_duree: payload.contrat_pe_duree,
      contrat_pe_commentaire: payload.contrat_pe_commentaire
    });
  },
  deleteContrat(id) {
    return request.delete(BASE + "/contrats" + "/" + id);
  },

  // CRUD PARAMS
  getContratParams(versionId) {
    return request.get(BASE + "/contrats/params" + "/" + versionId);
  },
  createContratParams(payload) {
    return request.put(BASE + "/contrats/params", {
      contrat_id: payload.contrat_id
    });
  },
  updateContratParams(payload) {
    return request.post(BASE + "/contrats/params", {
      id: payload.id,
      date_effet: payload.date_effet,
      contrat_type_id: payload.contrat_type_id,
      contrat_statut_id: payload.contrat_statut_id,
      contrat_siret_id: payload.contrat_siret_id,
      contrat_libelle: payload.contrat_libelle,
      contrat_mention_spe: payload.contrat_mention_spe,
      contrat_temps_travail: payload.contrat_temps_travail,
      contrat_position: payload.contrat_position,
      contrat_coef: payload.contrat_coef,
      contrat_brut_annuel: payload.contrat_brut_annuel,
      contrat_base_mensuelle: payload.contrat_base_mensuelle,
      contrat_taux_horaire: payload.contrat_taux_horaire,
      contrat_code_comptable: payload.contrat_code_comptable,
      contrat_rem_variable: payload.contrat_rem_variable,
      contrat_rtt: payload.contrat_rtt,
      contrat_temps_partiel: payload.contrat_temps_partiel,
      contrat_virement: payload.contrat_virement,
      contrat_bulletin_mail: payload.contrat_bulletin_mail,
      avantage_logement: payload.avantage_logement,
      avantage_nourriture: payload.avantage_nourriture,
      avantage_vehicule: payload.avantage_vehicule,
      avantage_logement_montant: payload.avantage_logement_montant,
      avantage_nourriture_montant: payload.avantage_nourriture_montant,
      avantage_vehicule_montant: payload.avantage_vehicule_montant,
      code_part_vehicule: payload.code_part_vehicule,
      code_part_vehicule_montant: payload.code_part_vehicule_montant,
      non_concurrence: payload.non_concurrence,
      promotion: payload.promotion,
      promotion_commentaire: payload.promotion_commentaire,
      augmentation: payload.augmentation,
      famille_id: payload.famille_id,
      cdd_activite: payload.cdd_activite,
      cdd_remplacement: payload.cdd_remplacement,
      cdd_alternance: payload.cdd_alternance
    });
  },
  deleteContratParams(id) {
    return request.delete(BASE + "/contrats/params" + "/" + id);
  },

  createVm(payload) {
    return request.put(BASE + "/vms", {
      vm_collaborateur_id: payload.vm_collaborateur_id
    });
  },
  updateVm(payload) {
    return request.post(BASE + "/vms", {
      id: payload.id,
      vm_type_id: payload.vm_type_id,
      vm_etat_id: payload.vm_etat_id,
      vm_organisme_id: payload.vm_organisme_id,
      vm_medecin_id: payload.vm_medecin_id,
      vm_date: payload.vm_date,
      vm_date_validite: payload.vm_date_validite,
      vm_aptitude_spe: payload.vm_aptitude_spe,
      vm_commentaire: payload.vm_commentaire
    });
  },
  deleteVm(id) {
    return request.delete(BASE + "/vms" + "/" + id);
  },

  createFormation(payload) {
    return request.put(BASE + "/formations", {
      formation_collaborateur_id: payload.formation_collaborateur_id
    });
  },
  updateFormation(payload) {
    return request.post(BASE + "/formations", {
      id: payload.id,
      formation_collaborateur_id: payload.formation_collaborateur_id,
      formation_etat_id: payload.formation_etat_id,
      formation_organisme_id: payload.formation_organisme_id,
      formation_type_id: payload.formation_type_id,
      formation_libelle_id: payload.formation_libelle_id,
      formation_attestation: payload.formation_attestation,
      formation_date_debut: payload.formation_date_debut,
      formation_date_fin: payload.formation_date_fin,
      formation_date_validite: payload.formation_date_validite,
      formation_facture_ref: payload.formation_facture_ref,
      formation_montant_arkadia: payload.formation_montant_arkadia,
      formation_opco_id: payload.formation_opco_id,
      formation_montant_opca: payload.formation_montant_opca,
      formation_commentaire: payload.formation_commentaire,
      formation_montant_total_ark: payload.formation_montant_total_ark,
      formation_avec_arkadia: payload.formation_avec_arkadia,
      formation_obligatoire: payload.formation_obligatoire,
      alert_delais: payload.alert_delais,
      alert_mail: payload.alert_mail,
      alert_niveau: payload.alert_niveau,
      formation_societe_id: payload.formation_societe_id,
      formation_nbre_heures: payload.formation_nbre_heures
    });
  },
  deleteFormation(id) {
    return request.delete(BASE + "/formations" + "/" + id);
  },

  createHabilitation(payload) {
    return request.put(BASE + "/habilitations", {
      habilitation_collaborateur_id: payload.habilitation_collaborateur_id
    });
  },
  updateHabilitation(payload) {
    return request.post(BASE + "/habilitations", {
      id: payload.id,
      habilitation_collaborateur_id: payload.habilitation_collaborateur_id,
      habilitation_libelle_id: payload.habilitation_libelle_id,
      habilitation_domaine_id: payload.habilitation_domaine_id,
      habilitation_date: payload.habilitation_date,
      habilitation_date_validite: payload.habilitation_date_validite,
      habilitation_commentaire: payload.habilitation_commentaire
    });
  },
  deleteHabilitation(id) {
    return request.delete(BASE + "/habilitations" + "/" + id);
  },

  createCompetence(payload) {
    return request.put(BASE + "/competences", {
      competence_collaborateur_id: payload.competence_collaborateur_id
    });
  },
  updateCompetence(payload) {
    return request.post(BASE + "/competences", {
      id: payload.id,
      competence_collaborateur_id: payload.competence_collaborateur_id,
      competence_libelle_id: payload.competence_libelle_id,
      competence_secteur_id: payload.competence_secteur_id,
      competence_niveau_id: payload.competence_niveau_id,
      competence_type_id: payload.competence_type_id,
      competence_critique: payload.competence_critique,
      competence_commentaire: payload.competence_commentaire,
      libelle_precision: payload.libelle_precision,
      date_identification: payload.date_identification
    });
  },
  deleteCompetence(id) {
    return request.delete(BASE + "/competences" + "/" + id);
  },

  updateTutorat(payload) {
    return request.post(BASE + "/tutorat", {
      id: payload.id,
      collaborateur_tuteur_id: payload.collaborateur_tuteur_id,
      collaborateur_tuteur_dispositif: payload.collaborateur_tuteur_dispositif,
      collaborateur_tuteur_bilan: payload.collaborateur_tuteur_bilan,
      collaborateur_tuteur_bilan_date: payload.collaborateur_tuteur_bilan_date,
      collaborateur_tuteur_bilan_1: payload.collaborateur_tuteur_bilan_1,
      collaborateur_tuteur_bilan_2: payload.collaborateur_tuteur_bilan_2,
      collaborateur_tuteur_bilan_3: payload.collaborateur_tuteur_bilan_3,
      collaborateur_tuteur_bilan_4: payload.collaborateur_tuteur_bilan_4,
      collaborateur_tuteur_bilan_5: payload.collaborateur_tuteur_bilan_5
    });
  },

  createAcces(payload) {
    return request.put(BASE + "/acces", {
      acces_collaborateur_id: payload.acces_collaborateur_id
    });
  },
  updateAcces(payload) {
    return request.post(BASE + "/acces", {
      id: payload.id,
      acces_collaborateur_id: payload.acces_collaborateur_id,
      acces_type_id: payload.acces_type_id,
      acces_site_client_id: payload.acces_site_client_id,
      acces_date_demande: payload.acces_date_demande,
      acces_date_validite: payload.acces_date_validite,
      acces_commentaire: payload.acces_commentaire
    });
  },
  deleteAcces(id) {
    return request.delete(BASE + "/acces" + "/" + id);
  },

  createMateriel(payload) {
    return request.put(BASE + "/materiels", {
      materiel_collab_id: payload.materiel_collab_id
    });
  },
  updateMateriel(payload) {
    return request.post(BASE + "/materiels", {
      id: payload.id,
      identifiant: payload.identifiant,
      description: payload.description,
      num_serie: payload.num_serie,
      lieu_stockage: payload.lieu_stockage,
      marque: payload.marque,
      modele: payload.modele,
      image: payload.image,
      materiel_etat_id: payload.materiel_etat_id,
      materiel_type_id: payload.materiel_type_id,
      materiel_collab_id: payload.materiel_collab_id,
      materiel_societe_id: payload.materiel_societe_id,
      materiel_fournisseur_id: payload.materiel_fournisseur_id,
      date_commande: payload.date_commande,
      date_livraison: payload.date_livraison,
      date_validite: payload.date_validite,
      commentaire: payload.commentaire
    });
  },
  deleteMateriel(id) {
    return request.delete(BASE + "/materiels" + "/" + id);
  },

  createEntretien(payload) {
    return request.put(BASE + "/entretiens", {
      collaborateur_id: payload.collaborateur_id
    });
  },
  updateEntretien(payload) {
    return request.post(BASE + "/entretiens", {
      id: payload.id,
      date_realisation: payload.date_realisation,
      date_renouvellement: payload.date_renouvellement,
      //faits_marquants: payload.faits_marquants,
      //synthese_annee_passee: payload.synthese_annee_passee,
      //synthese_qsser_annee_passee: payload.synthese_qsser_annee_passee,
      //objectif_annee_suivante: payload.objectif_annee_suivante,
      //formation_realisee: payload.formation_realisee,
      //formation_souhaitee: payload.formation_souhaitee,
      bilan: payload.bilan
    });
  },
  deleteEntretien(id) {
    return request.delete(BASE + "/entretiens" + "/" + id);
  },

  createExposition(payload) {
    return request.put(BASE + "/expositions", {
      exposition_collaborateur_id: payload.exposition_collaborateur_id
    });
  },
  updateExposition(payload) {
    return request.post(BASE + "/expositions", {
      id: payload.id,
      exposition_collaborateur_id: payload.exposition_collaborateur_id,
      exposition_type_id: payload.exposition_type_id,
      exposition_categorie_id: payload.exposition_categorie_id,
      exposition_date: payload.exposition_date,
      exposition_date_validite: payload.exposition_date_validite,
      exposition_dose_prevue: payload.exposition_dose_prevue,
      exposition_commentaire: payload.exposition_commentaire
    });
  },
  deleteExposition(id) {
    return request.delete(BASE + "/expositions" + "/" + id);
  },

  autorizeUser(payload) {
    return request.post(BASE + "/users/autorize", {
      user_id: payload.user_id,
      collaborateur_id: payload.collaborateur_id
    });
  },
  unautorizeUser(payload) {
    return request.post(BASE + "/users/unautorize", {
      user_id: payload.user_id,
      collaborateur_id: payload.collaborateur_id
    });
  },

  assignRole(payload) {
    return request.post("/user/role/assign", {
      user_id: payload.user_id,
      role: payload.role.name
    });
  },

  revokeRole(payload) {
    return request.post("/user/role/revoke", {
      user_id: payload.user_id,
      role: payload.role.name
    });
  },

  assignMooc(payload) {
    return request.post(BASE + "/mooc/assign", payload);
  },

  revokeMooc(payload) {
    return request.post(BASE + "/mooc/revoke", payload);
  },

  assignAccueil(payload) {
    return request.post(BASE + "/accueil/assign", payload);
  },

  revokeAccueil(payload) {
    return request.post(BASE + "/accueil/revoke", payload);
  }
};
